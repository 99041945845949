.cursorAuto {
  cursor: auto;
}
.cursorCrosshair {
  cursor: crosshair;
}
.cursorPointer {
  cursor: pointer;
}
.cursorMove {
  cursor: move;
}
.cursorResize {
  cursor: e-resize;
}
.cursorText {
  cursor: text;
}
.cursorWait {
  cursor: wait;
}
.cursorHelp {
  cursor: help;
}