#projectProperties {
  position: fixed;
  right: 24px;
  bottom: 80px;
  font-family: 'Advent Pro', sans-serif;

  table {
    td, th {
      padding: 0px 3px 0px 3px;
      background-color: white;
      border: 1px solid black;
      border-spacing: 0px;
      border-collapse: collapse;
    }

    th {
      font-weight: bold;
    }

    td {
      width: 100px;
    }

    .name {
      font-size: 18px;
      font-weight: bold;

      .closeWindow {
        float: right;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        font-weight: 200;
        color: black;
        font-size: 14px;
        margin-top: 3px;
        margin-right: 3px;
        text-align: right;
      }
    }
  }
}