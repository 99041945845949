* {
  outline: none;
}

body, html {
  height: 100%;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

header {
  position: relative;
  top: 0;
  z-index: 5;
}

footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  /*z-index: 4;*/
  min-height: 40px;
  width: auto;
  background: $verylight_grey;
  bottom: 0px;
  right: 0px;
  left: 0px;

  .text {
    display: flex;

    p {
      margin: 10px 0px 0px 10px;
    }
  }
  .help {
    display: flex;
    cursor: pointer;

    p {
      padding: 0 10px;
      margin: 0 !important;
      line-height: 40px;
    }
    span {
      opacity: 0.7;
      width: 45px;
      height: 40px;
      background: $light_red url('../img/pomoc.png') center no-repeat;
    }
  }
}

h4 {
  text-transform: uppercase;
}
