.projects-panel {
  display: block;
  padding: 20px;
  padding-left: 100px;
  position: fixed;
  left: 0px;
  z-index: 1;
  background-color: $verylight_grey;

  #back_to_editor {
    float: right;
    margin-right: 20px !important;
    padding: 17px !important;
  }

  .template {
    display: none;
  }

  .project-list {
    max-height: 85%;
    width: 100%;
    overflow: scroll;
    display: inline-block;
    overflow-x: hidden;

    .heading {
      display: inline-block;
      font-size: 0.9em;
      margin-left: 20px;
      padding: 10px;
      padding-top: 12px;
      background: #9e9e9e;
      text-transform: uppercase;
      border: none;
      color: #fff;
    }
  }

  .container-fluid {
    clear: both;

    &.own {
      border-bottom: 2px solid #9e9e9e;
      margin-bottom: 40px;
    }

    .project {
      display: inline-block;
      margin: 20px;
      position: relative;
      padding: 10px;
      cursor: pointer;

      &.readonly {

      }

      .label {
          position: absolute;
          top: 0px;
          right: 0px;
          z-index: 900;
      }

      p {
        text-align: center;
        padding: 20px;
      }

      .project-active {
        padding-top: 40px;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.87);
        min-height: 320px;
        width: 280px;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 1000;
        button {
          margin: 5px 0;
          cursor: pointer;
          img {
            float: left;
            height: 20px;
            width: 20px;
          }

        }
      }
    }
  }
}

#alert img.project_preview {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}