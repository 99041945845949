@media print {
  @page {
    size: A4 landscape;
    max-width: 100%;
    max-height: 100%;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #ececed;
  }

  header {
    display: none;
  }

  .preview-mode {
    .preview-image {
        width: 70%;
    }
  }
}