.preview-mode {
  background-color: #ececed;

  header {
    .fb-share-button {
      float: right;
      margin-top: 30px;
      margin-right: 15px;
    }

    .back {
      float: right;
      margin-top: 13px;
      margin-right: 15px;
    }

    .print {
      float: right;
      margin-top: 18px !important;
      margin-right: 15px !important;;
    }
  }

  .content {
    text-align: center;

    .preview-image {
    }
  }
}

