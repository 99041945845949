
nav {
  min-width: 1375px;
  height: 80px;
  background-color: $verylight_grey;
  margin: 0 !important;
  padding-bottom: 20px;
  .nav {
    padding-top: 20px !important;
    li {
      display: inline-block !important;
      a {
        display: inline-block !important;
      }
    }
  }

  .navbar-login {
    float: right;
    display: inline-block;
    cursor: pointer;
    margin-top: 35px;
    p {

      span {
        display: inline-block;
        transform: rotate(45deg);
        font-size: 1.2em;
        margin: 2px 15px;
        border-bottom: 0.5px solid black;
        border-right: 0.5px solid black;
        border-top: none;
        border-left: none;
        width: 10px;
        height: 10px;
      }
    }
  }

}


