#log {
  z-index: 2 !important;
}

.dropdown-form {
  position: fixed;
  right: 0px;
  background: white;
  z-index: 4;
  padding: 25px;
  width: 300px;
  -webkit-box-shadow: -2px 1px 20px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -2px 1px 20px 0px rgba(0, 0, 0, 0.19);
  box-shadow: -2px 1px 20px 0px rgba(0, 0, 0, 0.19);
  text-align: center;
  form {
    width: 200px;
    text-align: left;
    margin: 0 auto;
    small {
      cursor: pointer;
    }
    label {
      font-size: 0.9em;
    }
  }
  a.facebook-login {
    &:hover, &:visited, &:focus {
      text-decoration: none;
    }
    .button-default {
      color: white !important;
      text-transform: uppercase !important;
      text-align: center;
    }
  }
}

input[type="submit"], button {
  margin: 5px 0 !important;
  cursor: pointer !important;
  color: white !important;
  text-transform: uppercase !important;
}

.button-small {
  border-radius: 5px;
  min-width: 100px;
  padding: 10px;
  border: none;
}
.button-default {
  border-radius: 5px;
  min-width: 200px;
  padding: 10px;
  border: none;

}
.button-blue {
  background: $light_blue;
}

.button-red {
  background: $light_red;
  margin: 0 auto;
}

.button-lightgrey {
  background: $light_grey;
}

.button-basics {
  color: $light_grey;
}

.button-white {
  color: white;
  border: 1px solid white;

  background: none;
}

.button-delete{
  color: darkred;
  border: 1px solid darkred;
  background: none;
}

.button-save{
  color: darkred;
  border: 1px solid darkred;
  background: none;
}

.fb-share-button {
  margin-top: 20px;
}