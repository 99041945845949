$button-background-size: 64px;
$button-position-correction: -($button-background-size - $button-size) / 2;

#menu {
  select {
    float: left;
    margin-top: 20px;
    margin-left: 15px;
    height: 32px;

  }

  .button {
    width: $button-size;
    height: $button-size;

    background-position-y: $button-position-correction;
    background-position-x: $button-position-correction;

    margin-top: 15px;
    cursor: pointer;

    float: left;

    &:hover {
      background-position-y: $button-background-size * 2 + $button-position-correction;
    }

    &.selected {
      background-position-y: $button-background-size + $button-position-correction;
    }
  }

  .separator {
    width: 30px;
    height: $button-size;
    float: left;
  }

  .newStage {
    background-image: url('../img/menu/nowy.png');
  }

  .saveStage {
    background-image: url('../img/menu/zapis.png');
  }

  .saveAsStage {
    background-image: url('../img/menu/zapisjako.png');
  }

  .undo {
    background-image: url('../img/menu/cofnij.png');
  }

  .redo {
    background-image: url('../img/menu/dalej.png');
  }

  .newWall {
    background-image: url('../img/menu/sciana.png');
  }

  .newRoom {
    background-image: url('../img/menu/czworobok.png');
  }

  .delete {
    background-image: url('../img/menu/usun.png');
  }

  .flipVertical {
    background-image: url('../img/menu/odbij_poziomo.png');
  }

  .flipHorizontal {
    background-image: url('../img/menu/odbij_pionowo.png');
  }

  .newLabel {
    background-image: url('../img/menu/etykieta.png');
  }

  .preview {
    background-image: url('../img/menu/podglad.png');
  }

  .size {
    background-image: url('../img/menu/linijka.png');
  }

  .disableRoomArea {
    background-image: url('../img/menu/wylacz_powierzchnie.png');
    display: none;
  }

  .eye {
    background-image: url('../img/menu/oko.png');
    position: relative;

    .layers {
      display: none;
      position: absolute;
      box-sizing: content-box;

      top: 60px;
      left: 0px;

      width: 150px;
      padding: 20px 20px 20px 15px;

      background-color: #f8f8f8;

      label {
        font-weight: 300;
        cursor: pointer;
        input {
          width: 30px;
        }
      }
    }
  }

  .screenshot {
    background-image: url('../img/menu/aparat.png');
  }

  .rotateLeft {
    background-image: url('../img/menu/petla_lewa.png');
  }

  .rotateRight {
    background-image: url('../img/menu/petla_prawa.png');
  }

  .zoomin {
    background-image: url('../img/menu/powiekszenie.png');
    display: none;
  }

  .zoomout {
    background-image: url('../img/menu/pomniejszenie.png');
    display: none;
  }

  .fullscreen {
    background-image: url('../img/menu/fullscreen.png');

    &.inverse {
      background-image: url('../img/menu/smallscreen.png') !important;
    }
  }

  .floors {
    float: left;

    .floor0 {
      background-image: url('../img/menu/0floor.png');
    }

    .floor1 {
      background-image: url('../img/menu/1floor.png');
    }

  }
}

