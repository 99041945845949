$library-item-size: 64px;

.library-activemenuitem {
  background: #eee url('../img/schowaj.png') no-repeat center !important;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  &:hover {
    transform: rotate(0deg) !important;
  }
}

.library-widget {
  position: fixed;
  z-index: 3;
  top: 150px;
  height: 250px;
  left: -360px;
  width: 450px;

  .library-menu {
    .stairs {
      display: none;
    }
    .library-menuitem {
      &:hover {
        transform: rotate(180deg);
        background: #eee url('../img/schowaj.png') no-repeat center !important;
        background-size: cover;
        -webkit-transition: background-image 0.2s ease-in-out;
        transition: background-image 0.2s ease-in-out;
      }
    }
  }

  .library-contain {
    width: 360px;
    min-height: 541px;
    background-color: #fff;
    float: left;

    .library-header {
      min-height: 80px;
    }

    .library-options {
      display: flex;
      margin: 5px;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      overflow-y: auto;
      height: 100%;
      max-height: 600px;

      .library-option {
        margin: 5px;
        width: 100px;
        height: 120px;
        background: white;
        text-align: center;
        line-height: 120px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

  }
  .library-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90px;
    background: white;
    border: 1px solid $light_grey;
    .library-menuitem {
      min-height: 90px;
      cursor: pointer;

    }
  }
}


