body, html {
  margin: 0px;
  width: 100%;
  height: 100%;
}

#download {
  display: none;
}

#board {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;

  position: absolute;
  top: 80px;
  height: calc(100vh - 120px);
  width: calc(100vw);

  overflow: scroll;

  #rendererWindow {
    box-sizing: border-box;
    height: 3000px;
    width: 3000px;
  }
}

#cache {
  position: absolute;
  z-index: -100;
}

#surfaceSizeWindow {

  border: 1px solid red;
  position: absolute;
  right: 10px;
  bottom: 50px;
  z-index: 10000;

  display: none;

  #surfaceSizeCanvas {
    width: 400px;
    height: 200px;
  }
}

#statusBar {
  position: fixed;
  height: 25px;
  bottom: 0px;
  left: 0px;
  width: 100%;

  background-color: #ddd;
  z-index: 500;

  &.active {

  }
}

.content-view {
  position: absolute;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /*  z-index: 1;*/
  left: 0;
  right: 0;
  bottom: 40px;
  top: 80px;
  box-shadow: none;
}

.help-section {
  display: block;
  z-index: 3;
  overflow: auto;

  a {
    color: black;
  }

  h4 {
    color: $light_red;
    padding: 20px 0;
  }
  form {
    textarea {
      border: 1px solid $light_blue;
    }
    input[type=button], input[type=submit] {
      text-transform: uppercase;
      color: white;
    }
    h4 {
      color: $light_blue;
    }
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;

    display: block;
    width: 20px;
    height: 20px;

    cursor: pointer;

    font-weight: 200;
  }
}

.projects-lib {
  margin-top: 20px;

  div {
    display: inline-block;
    padding: 0 20px;
    border-right: 1px solid $dark_grey;
  }

  button {
    font-size: 0.9em;
    margin-left: 20px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);
    border: none;
  }
}

#registration {
  z-index: 10;
  background: white;
  margin-top: -200px;
  form {
    width: 300px !important;

    .marketing-agree {
      float: left;
      margin-right: 10px;
    }
  }
}

.library-widget__onclick {
  position: absolute;
  left: 350px;
}

.active {
  span {
    border-top: 0.5px solid black;
    border-left: 0.5px solid black;
  }
}

.disabled {
  opacity: 0.75;
}

.blackOut {
  opacity: 0.5;
}

.modal-footer {
  border: none;
}

.modal-header {
  font-weight: bold;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 99999;
  display: none;

  background-color: white;
  background-image: url('../img/preloader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.adminTool {
  display: none;
}